export const jcbData = [
  {
    name: "S2632E Electric Scissor",
    category: "scissor",
    img: require("../assets/jcb/S2632E.jpg"),
    description:
      "The JCB S2362E Electric Scissor comes with a 9.92m working height and platform height of 7.92m with better productivity,safety & durability.",
    price: 0,
    maxWHeight: 9.92,
    maxPHeight: 7.92,
    platformCapacity: 250,
    outDoorRating: "No",
    overallWeight: 2187,
    platformOccupancy: 2,
    brochure: require("../assets/jcb/scissr.pdf"),
  },
  {
    name: "S2646E Electric Scissor",
    category: "scissor",
    img: require("../assets/jcb/S2646E.jpeg"),
    description:
      "The S2646E Electric Scissor Lift is a robust machine that enables you to reach vertical heights with ease at a working height of 9.90m reliably.",
    price: 0,
    maxWHeight: 9.9,
    maxPHeight: 7.9,
    platformCapacity: 450,
    outDoorRating: "Yes",
    overallWeight: 2647,
    platformOccupancy: 2,
    brochure: require("../assets/jcb/scissr.pdf"),
  },
  {
    name: "S3246E Electric Scissor",
    category: "scissor",
    img: require("../assets/jcb/S3246E.jpeg"),
    description:
      "The S3246E Electric Scissor with a platform height of 10m designed with many unique features making it safe,easy to operate, reliable and serviceability as standard.",
    price: 0,
    maxWHeight: 11.68,
    maxPHeight: 9.68,
    platformCapacity: 320,
    outDoorRating: "Yes",
    overallWeight: 2852,
    platformOccupancy: 2,
    brochure: require("../assets/jcb/scissr.pdf"),
  },
  {
    name: "S4046E Electric Scissor",
    category: "scissor",
    img: require("../assets/jcb/S4046E.jpeg"),
    description:
      "The S4046E has been designed in-house with many features and comes with a 13.9m working height and ease of operation.",
    price: 0,
    maxWHeight: 13.9,
    maxPHeight: 11.9,
    platformCapacity: 320,
    outDoorRating: "No",
    overallWeight: 3060,
    platformOccupancy: 2,
    brochure: require("../assets/jcb/scissr.pdf"),
  },
  {
    name: "S4550E Electric Scissor",
    category: "scissor",
    img: require("../assets/jcb/S4550E.jpg"),
    description:
      "The S4550E is a versatile electric scissor lift designed to be reliable even in extreme working conditions with a weight capacity of 230kg.",
    price: 0,
    maxWHeight: 15.8,
    maxPHeight: 13.8,
    platformCapacity: 230,
    outDoorRating: "No",
    overallWeight: 3538,
    platformOccupancy: 2,
    brochure: require("../assets/jcb/scissr.pdf"),
  },

  {
    name: "S1932E Electric Scissor",
    category: "scissor",
    img: require("../assets/jcb/scissor.jpeg"),
    description:
      "The S4550E is compact electric scissor lift for operating in tight, restrictive spaces.",
    price: 0,
    maxWHeight: 15.8,
    maxPHeight: 13.8,
    platformCapacity: 230,
    outDoorRating: "No",
    overallWeight: 3538,
    platformOccupancy: 2,
    brochure: require("../assets/jcb/scissr.pdf"),
  },

  {
    name: "AJ48D",
    category: "articulated",
    img: require("../assets/jcb/AJ48D.jpeg"),
    description:
      "The AJ48D is the most versatile access platform yet in the JCB range, offering extensive height and reach in the toughest environments.",
    price: 0,
    boomType: "Articulated",
    maxWHeight: 16.7,
    maxOutReach: 8.3,
    platformCapacity: 250,
    powerType: "Diesel",
    platformHeight: 14.7,
    brochure: require("../assets/jcb/boom.pdf"),
  },

  {
    name: "A45E",
    category: "articulated",
    img: require("../assets/jcb/boom.jpeg"),
    description:
      "The A45E is our first all-electric articulated boom and part of the JCB E-TECH range.It is more productive and efficient, with zero emissions, making it ideal for indoor and outdoor use.",
    price: 0,
    boomType: "Articulated",
    maxWHeight: 15.57,
    maxOutReach: 8.056,
    platformCapacity: 300,
    powerType: "Electric",
    platformHeight: 13.84,
    brochure: require("../assets/jcb/boom.pdf"),
  },
  {
    name: "JCB G60QI CPCB IV+",
    category: "generator",
    img: require("../assets/jcb/generator.jpeg"),
    description:
      "The JCB G60QI CPCB IV+ Generator delivers reliable power with lower emissions. Compact & efficient, it boasts a 10% larger fuel tank.",
    price: 0,
    brochure: require("../assets/jcb/generator.pdf"),
    dryWeight: 1700,
    fuelTankCapacity: 186,
    powerRating: 58.5,
    length: 2.5,
  },
  {
    name: "T65D",
    category: "telescopic",
    img: require("../assets/jcb/t65.jpeg"),
    description:
      "Reach even higher standards of performance, reliability, efficiency, comfort and safety with our very first diesel telescopic boom platform, the T65D.",
    price: 0,
    maxWHeight: 21.67,
    maxOutReach: 17.25,
    platformCapacity: 450,
    powerType: "Diesel",
    platformHeight: 19.92,
    brochure: require("../assets/jcb/t65.pdf"),
  },
];
