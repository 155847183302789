import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { JCBSlider } from "../components/jcb-slider";
import { useModelContext } from "../components/ModelProvider";
import { jcbData } from "../json/jcb";

const JCB = () => {
  const { setOpenEnq, setModel } = useModelContext();

  // Set up the tab state to control active category
  const [activeTab, setActiveTab] = useState("scissor");

  // Filter the data based on active tab
  const filteredData = jcbData.filter((item) => item.category === activeTab);

  const slides = [
    { image: require("../assets/jcb/boom.jpeg") },
    { image: require("../assets/jcb/generator.jpeg") },
    { image: require("../assets/jcb/t65.jpeg") },
  ];

  return (
    <>
      <Helmet>
        <title>JCB Dealers Hyderabad | JCB Showroom Near Me</title>
      </Helmet>
      <JCBSlider slides={slides} setOpenEnq={setOpenEnq} setModel={setModel} />

      {/* breadcrumb */}
      <div className="py-4 bg-gray-100 md:py-10">
        <div className="container mx-auto lg:max-w-7xl">
          <div className="flex flex-wrap items-center justify-center gap-y-2 md:justify-between">
            <div className="items-center hidden space-x-2 text-center sm:space-x-5 md:flex">
              <p className="font-semibold">Orange Auto</p>
              <div className="h-[15px] w-[2px] bg-black"></div>
              <p>Authorised Dealer for JCB Access & Silent Genset</p>
            </div>

            <div className="flex items-center px-2 space-x-2 sm:space-x-5">
              <Link to="/" className="text-secondary">
                Home
              </Link>
              <div className="h-[15px] w-[2px] bg-black"></div>
              <p>JCB</p>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs for Categories */}
      <div className="tabs grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 px-5 gap-1 md:gap-5 mt-10 container mx-auto">
        <button
          onClick={() => setActiveTab("scissor")}
          className={`py-2 px-6 rounded ${
            activeTab === "scissor"
              ? "bg-yellow-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Scissors
        </button>
        <button
          onClick={() => setActiveTab("articulated")}
          className={`py-2 px-6 rounded ${
            activeTab === "articulated"
              ? "bg-yellow-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Articulated
        </button>
        <button
          onClick={() => setActiveTab("generator")}
          className={`py-2 px-6 rounded ${
            activeTab === "generator"
              ? "bg-yellow-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Generator
        </button>
        <button
          onClick={() => setActiveTab("telescopic")}
          className={`py-2 px-6 rounded ${
            activeTab === "telescopic"
              ? "bg-yellow-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Telescopic
        </button>
      </div>

      {/* Product Listings */}
      <div className="max-w-7xl mx-auto my-10 space-y-5 p-5">
        {filteredData.map((item, index) => (
          <div
            key={index}
            className="border border-gray-200 rounded-lg overflow-hidden shadow-lg"
          >
            <div className="flex flex-col lg:flex-row">
              <img
                src={item.img}
                alt="jcb"
                className="w-full lg:w-1/3 h-60 object-cover"
              />
              <div className="relative p-5 lg:p-10 space-y-4 flex-1">
                <h1 className="text-2xl md:text-3xl font-semibold">
                  {item.name}
                </h1>
                <p className="text-sm leading-7 text-black/70">
                  {item.description}
                </p>
                <div
                  className={`grid gap-1 grid-cols-1 md:gap-0 ${
                    item.category === "scissor"
                      ? "md:grid-cols-3"
                      : "sm:grid-cols-2"
                  }`}
                >
                  <a
                    href={item.brochure}
                    rel="noreferrer"
                    target="_blank"
                    className="bg-black text-white w-full py-3 px-4 text-center border-r-2 border-yellow-500"
                  >
                    Download Brochure
                  </a>
                  <button
                    onClick={() => {
                      setOpenEnq(true);
                      setModel(item.name);
                    }}
                    className="bg-black text-white w-full py-3 px-4 text-center"
                  >
                    Request Quote
                  </button>
                  {item?.category === "scissor" && (
                    <a
                      href={item.brochure}
                      rel="noreferrer"
                      target="_blank"
                      className="bg-yellow-500 text-white w-full py-3 px-4 text-center border-r-2 border-yellow-500"
                    >
                      View More
                    </a>
                  )}
                </div>
              </div>
            </div>

            {/* Product Specs */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 px-5 py-6">
              {/* Max Working Height */}
              {item.maxWHeight && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Max Working Height
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.maxWHeight} m
                  </h6>
                </div>
              )}

              {/* Max Platform Height */}
              {item.maxPHeight && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Max Platform Height
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.maxPHeight} m
                  </h6>
                </div>
              )}

              {/* Max Platform Capacity */}
              {item.platformCapacity && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Max Platform Capacity
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.platformCapacity} kg
                  </h6>
                </div>
              )}

              {/* Outdoor Rating */}
              {item.outDoorRating && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Outdoor Rating
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.outDoorRating}
                  </h6>
                </div>
              )}

              {/* Overall Weight */}
              {item.overallWeight && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Overall Weight
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.overallWeight} kg
                  </h6>
                </div>
              )}

              {/* Platform Occupancy */}
              {item.platformOccupancy && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Platform Occupancy
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.platformOccupancy}
                  </h6>
                </div>
              )}

              {/* Max Outreach */}
              {item.maxOutReach && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Max Outreach
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.maxOutReach} m
                  </h6>
                </div>
              )}

              {/* Power Type */}
              {item.powerType && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Power Type
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.powerType}
                  </h6>
                </div>
              )}

              {/* Platform Height */}
              {item.platformHeight && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Platform Height
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.platformHeight} m
                  </h6>
                </div>
              )}

              {/* Boom Type */}
              {item.boomType && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Boom Type
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.boomType}
                  </h6>
                </div>
              )}

              {/* Dry Weight */}
              {item.dryWeight && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Dry Weight
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.dryWeight}
                  </h6>
                </div>
              )}

              {/* Fuel Tank Capacity */}
              {item.fuelTankCapacity && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Fuel Tank Capacity
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.fuelTankCapacity}
                  </h6>
                </div>
              )}

              {/* Power Rating */}
              {item.powerRating && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">
                    Prime Power Rating
                  </p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.powerRating}
                  </h6>
                </div>
              )}

              {/* Length */}
              {item.length && (
                <div>
                  <p className="text-sm font-semibold text-gray-500">Length</p>
                  <h6 className="font-semibold text-lg text-black/70">
                    {item.length}
                  </h6>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default JCB;
