import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { GrFormNext } from "react-icons/gr";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useRef } from "react";

export const JCBSlider = ({ slides, setOpenEnq }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  return (
    <Swiper
      spaceBetween={2}
      pagination={{ dynamicBullets: true }}
      speed={500}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      grabCursor={true}
      navigation={{
        nextEl: navigationNextRef.current,
        prevEl: navigationPrevRef.current,
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
      modules={[Pagination, Autoplay, Navigation]}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="relative bg-primary">
            <img
              src={slide.image}
              className="object-cover max-w-full h-[700px] w-full mx-auto overflow-hidden"
              alt={slide.title}
            />
            {/* {slide?.title && ( */}
            <div className="absolute z-20 hidden w-full text-white bottom-20 md:block left-4 lg:bottom-32">
              <div className="container mx-auto">
                <div className="w-full mb-4 text-4xl text-secondary lg:mb-6">
                  Welcome to <br />
                  <span className="text-5xl lg:text-6xl">JCB</span>
                </div>
                <button
                  onClick={() => {
                    setOpenEnq(true);
                  }}
                  className="bg-yellow-500 text-black/80 text-center px-4 py-2 rounded"
                >
                  Request Price
                </button>
              </div>
            </div>
            {/* )} */}
          </div>
        </SwiperSlide>
      ))}

      <div
        ref={navigationPrevRef}
        className="absolute z-10 p-1 rounded-full cursor-pointer left-3 sm:left-10 top-1/2 sm:top-1/2 bg-white/60 hover:bg-[#F17D28] sm:p-3 shadow-xl"
      >
        <GrFormNext className="rotate-180" />
      </div>
      <div
        ref={navigationNextRef}
        className="absolute z-10 p-1 rounded-full cursor-pointer right-3 sm:right-10 top-1/2 sm:top-1/2 bg-white/60 hover:bg-[#F17D28] sm:p-3 shadow-xl"
      >
        <GrFormNext />
      </div>
    </Swiper>
  );
};
